<template>
  <div class="card_krssu relative m-2 px-4 flex flex-col w-1/4 h-96">
    <div style="flex: 0 1 auto">
      <div
        v-if="selected"
        class="absolute right-4 top-4"
        v-html="getSvg(colors.mainBlue, 36, 36, 'done')"
      ></div>
      <div
        v-else
        class="hover_icon absolute right-4 top-4 opacity-50"
        v-html="getSvg(colors.mainBlue, 36, 36, 'done')"
      ></div>
      <div class="text-2xl text-sblue-600 pt-6 pb-2">
        {{ element.package_name }}
      </div>
      <div class="text-left text-sblue-300 text-3xl mt-1 mb-4">
        {{ element.package_prices[0].currency_symbol }}
        {{ element.package_prices[0].price }}
        <span class="text-sblue-200 text-3xl">/mo</span>
      </div>
      <div
        class="text-lg text-sblue-400 mb-3 pb-3 border-b-2"
        style="border-color: rgba(171, 223, 237, 0.5"
      >
        {{ element.package_description }}
      </div>
    </div>
    <div style="flex: 1 1 auto" class="overflow-auto">
      <div
        class="flex space-y-1 items-center text-base"
        v-for="feature in element.feature_list"
        :key="feature"
      >
        <div class="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#2eafd3"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <div style="color: #3fafd4">
          {{ feature }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvg } from "../utils/getSvg";
import { getColors } from "../utils/getColors";

export default {
  name: "BigCard",
  props: { element: { type: Object }, selected: { type: Boolean } },
  data() {
    return {
      colors: {},
    };
  },
  mounted() {
    this.colors = getColors;
  },
  methods: { getSvg: getSvg },
};
</script>

<style scoped>
.card_krssu {
  box-shadow: 0px 20px 50px #75bdd133;
  border: 1px solid #2eafd3;
  border-radius: 20px;
  min-width: 240px;
  min-width: 340px;
}

.card_krssu .hover_icon {
  visibility: hidden;
}

.card_krssu:hover .hover_icon {
  visibility: visible;
}

.freq_krssu {
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0px;
  color: #c0e7f1;
  opacity: 1;
  margin-top: 47px;
}
</style>
