<template>
  <div v-if="plans">
    <div lass="w-full flex justify-center flex-col items-center">
      <div
        class="w-full flex-col justify-center items-center align-middle my-auto"
      >
        <div
          class="
            text-3xl
            2xl:text-5xl
            text-center
            font-extrabold
            text-sblue-600
            mt-2
          "
        >
          You're trying the {{ selectedPlan.package_name }}
          <span v-if="selectedPlan.package_prices[0].trial_period_days">
            Free for
            {{ selectedPlan.package_prices[0].trial_period_days }} Days!</span
          >
        </div>
        <div class="text-center mb-10 mt-2 2xl:text-3xl text-xl text-sblue-300">
          To unlock more features upgrade your plan here
        </div>

        <div
          class="
            flex
            justify-around
            flex-wrap
            items-center
            align-middle
            mt-10
            mb-14
            px-32
          "
        >
          <BigCard
            @click="changePlan(plan)"
            v-for="plan in plans"
            :key="plan.id"
            :element="plan"
            :selected="selectedPlan ? plan.id == selectedPlan.id : null"
          />
        </div>
        <div class="w-full flex justify-center flex-col align-middle mx-auto">
          <div
            class="
              w-full
              text-center
              flex
              justify-center
              align-middle
              items-center
            "
          >
            <button
              type="submit"
              class="
                font-thin
                cursor-pointer
                text-xl
                bg-sblue-400
                text-white text-center
                py-4
                flex
                justify-around
                items-center
                px-12
                2xl:text-3xl
                button_shadow
                rounded-lg
              "
              @click="handleSubmit"
            >
              <div v-html="getSvg('#fff', 22.237, 22.237, 'magicWand')"></div>
              <div style="margin-left: 19px">Finish</div>
            </button>
          </div>
        </div>

        <div
          v-if="this.$store.state.platform_id != 13"
          class="flex w-full justify-center items-center mt-2"
        >
          <input
            title="By selecting this option, your loyalty program will be live and the Kangaroo Rewards widget will be published to your website."
            type="checkbox"
            id="checkbox"
            v-model="checked"
          />
          <label
            title="By selecting this option, your loyalty program will be live and the Kangaroo Rewards widget will be published to your website."
            class="ml-4 text-sblue-600"
            for="checkbox"
            >Go live</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BigCard from "@/components/BigCard.vue";

import { getSvg } from "@/utils/getSvg";
import { getColors } from "@/utils/getColors";

export default {
  name: "Packages",
  title: "Select your plan",
  components: {
    BigCard,
  },
  data() {
    return {
      plans: null,
      selectedPlan: null,
      colors: {},
      checked: true,
    };
  },
  watch: {
    $route: function () {
      window.history.forward(1);
    },
  },
  mounted() {
    this.getPlans();
    this.colors = getColors;
  },
  methods: {
    getSvg: getSvg,
    async getPlans() {
      try {
        const plans = await fetch(
          `https://${process.env.VUE_APP_API_URL}/business-packages`,
          {
            method: "GET",
            headers: new Headers({
              Authorization: `Bearer ${this.$store.state.access_token}`,
              "X-Application-Key": this.$store.state.application_key,
            }),
          },
        );
        const data = await plans.json();
        this.plans = data.data;
        console.log("Plans: ", this.plans);
        this.selectedPlan = this.plans[0];
      } catch (error) {
        console.log("Error: " + error);
      }
    },
    changePlan(plan) {
      this.selectedPlan = plan;
    },

    async handleSubmit() {
      console.log("Selected plan: ", this.selectedPlan);
      try {
        console.log("Selected plan: ", this.selectedPlan.package_prices[0].id);
        const planSubmit = await fetch(
          `https://${process.env.VUE_APP_API_URL}/business-package-subscriptions`,
          {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${this.$store.state.access_token}`,
              Accept: "application/vnd.kangaroorewards.api.v1+json;",
              "Content-Type": "application/json",
              "X-Application-Key": this.$store.state.application_key,
            }),
            body: JSON.stringify({
              price_id: this.selectedPlan.package_prices[0].id,
              success_url: this.$store.state.redirectUrl,
              cancel_url: `${process.env.VUE_APP_CANCEL_URL}`,
              auto_publish_integration:
                this.$store.state.platform_id == 13 ? false : this.checked,
            }),
          },
        );
        const data = await planSubmit.json();

        console.log("Plans: ", data);

        window.location.href = data.data.redirect_url;
      } catch (error) {
        console.log("Error: ", error);
      }
    },
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  background: #2eafd3;
}
</style>
